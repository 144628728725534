import {faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './Contact.css'
import './LinkBar.css'
export const Contact = () => {
  return (
    <div>
    <div className='container'>
      <h1 className='header'>I am always looking to grow my network</h1>
    </div>
    <div className='links'>
        <div className="favicon-bar">
        <FontAwesomeIcon icon={faPhone} />
        (321)-634-4983
        </div>    
    </div>
    </div>
  )
}
