import './App.css';
import { NavBar } from './NavBar';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import { About } from './About';
import { Contact } from './Contact';
import {Projects} from './Projects';
import { Home } from './Home';
const App = () => {

  return (
    <>
    <Router>
    <NavBar />
    <Routes>
      <Route path="/" Component={Home}/>
      <Route path="/about" Component={About} />
      <Route path="/contact" Component={Contact} />
      <Route path="/projects" Component={Projects} />
    </Routes>
    </Router>
    </>
  );
};

export default App;
