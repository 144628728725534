import React, { useState, useEffect } from 'react';
import './TypingAnimation.css';

interface TypingAnimationProps {
  text: string;
  speed: number;
}

const TypingAnimation: React.FC<TypingAnimationProps> = ({ text, speed }) => {
  const [displayText, setDisplayText] = useState('');
  const [isCursorVisible, setIsCursorVisible] = useState(true);
  
  
  useEffect(() => {
    const typingTimer = setTimeout(() => {
      const textLength = displayText.length;
      const textToType = text.substring(textLength, textLength + 1);
      setDisplayText(displayText + textToType);
    }, speed);

    return () => clearTimeout(typingTimer);
  }, [displayText, text, speed]);

  useEffect(() => {
    const cursorTimer = setInterval(() => {
      setIsCursorVisible((prevIsCursorVisible) => !prevIsCursorVisible);
    }, 500);

    return () => clearInterval(cursorTimer);
  }, []);

  return (
    <div>
      <span className = "type">{displayText}</span>
      {isCursorVisible && <span className="cursor">|</span>}
    </div>
  );
};

export default TypingAnimation;
