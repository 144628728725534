import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope} from "@fortawesome/free-solid-svg-icons";
import "./LinkBar.css"
import {faLinkedin} from "@fortawesome/free-brands-svg-icons";
import {faGithub} from "@fortawesome/free-brands-svg-icons";
const email = "jmvig37@gmail.com"
export const LinkBar = () => {
  return (
    <div>
        <div className="favicon-bar">
        <a href="https://www.linkedin.com/in/jmvigeant/">
        <FontAwesomeIcon icon={faLinkedin}/></a>    
        <a href={`mailto:${email}`}>
        <FontAwesomeIcon icon={faEnvelope} />
        </a>
        <a href = "https://github.com/JakeVigeant12">
        <FontAwesomeIcon icon={faGithub} />
        </a>
        </div>    
    </div>
  )
}
