import axios from 'axios';
import React, { useState, useEffect } from 'react';
import "./Projects.css"
const projects_url = 'https://api.github.com/users/JakeVigeant12/repos';
const language_to_badge = new Map()

language_to_badge.set("reactJS", "https://img.shields.io/badge/-ReactJs-61DAFB?logo=react&logoColor=white")
language_to_badge.set("python", "https://img.shields.io/badge/python-3670A0?style=for-the-badge&logo=python&logoColor=ffdd54")
language_to_badge.set("swift", "https://img.shields.io/badge/Swift-FA7343?style=for-the-badge&logo=swift&logoColor=white")
language_to_badge.set("javascript", "https://img.shields.io/badge/JavaScript-323330?style=for-the-badge&logo=javascript&logoColor=F7DF1E")
language_to_badge.set("java", "https://img.shields.io/badge/Java-ED8B00?style=for-the-badge&logo=openjdk&logoColor=white")
language_to_badge.set("html", "https://img.shields.io/badge/HTML-239120?style=for-the-badge&logo=html5&logoColor=white")




export const Projects = () => {
  const [projects, setProjects] = useState([]);


  const getProjects = async () => {
    const { data } = await axios.get(projects_url);
    setProjects(data);
  };

  useEffect(() => {
    getProjects();
  }, []);

  return (
    <div className = 'projects-grid'>
      {projects.map((project: any) => (
    project.stargazers_count > 0 && (

      <div key={project.id} className="projects-grid">
        <h3>{project.name.replace(/-/g, " ")}</h3>
        <p>{project.description}</p>
        <img 
  src={language_to_badge.get(project.language.toLowerCase())} 
  alt={`${project.language} Badge`} 
/>
   
      </div>
    )))}
    </div>
  )
}
